import * as React from "react"
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import Grid from '@material-ui/core/Grid';
import Layout from "../components/layout"
import Seo from "../components/seo"
import NewsHighlight from "../components/newshighlight";
const MOBILE_BREAKPOINT = 721;
const isClient = typeof window !== 'undefined';

class NewsPage extends React.Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };
  constructor(props) {
    super(props)
    this.state = {
      viewportWidth: isClient ? window.innerWidth : 0,     
    }
  }
  updateWindowDimensions = () => {
    this.setState({ viewportWidth: window.innerWidth });
  }
  componentDidMount() {
    if (isClient) {
      this.updateWindowDimensions();
      window.addEventListener('resize', this.updateWindowDimensions);
    }
  }
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')   
    const keywords = get(this, 'props.data.site.siteMetadata.keywords')
    const pageName = "News"

    const {viewportWidth,} = this.state;    
    const isMobile = Boolean(viewportWidth <= MOBILE_BREAKPOINT);
    var pagewidth = {
      matches: false,
      media: '(max-width: 600px)',
      onchange: null,
    }
    if (typeof window !== 'undefined') {
      pagewidth = window.matchMedia('(max-width: 600px)')
    }

    var blogposts = get(this, 'props.data.allContentfulNewsArticle.edges')    
    var posts = []    
    for (var i = 0; i < blogposts.length; i++) {      
      var slugexists = false;
      for (var j =0; j < posts.length; j++) {
        if (blogposts[i].node.slug === posts[j].node.slug) { slugexists = true;}
      }
      if (slugexists === false & posts.length < 4) { posts.push(blogposts[i])}
    }    

    return (
      <React.Fragment>
        <Layout data={this.props} pagewidth={pagewidth.matches}>
        <Seo pagename={pageName +" "+ siteTitle}  keywords={keywords}/>          
          <div className="page_wrapper generalpage_wrapper">
            <Grid container spacing={0}>              
              <Grid item xs={12}>
                <NewsHighlight pagewidth={isMobile} posts={posts} />
              </Grid>
            </Grid>
          </div>
        </Layout>
      </React.Fragment>
      
    )}
}

export default withCookies(NewsPage)
export const NewsPageQuery = graphql`
  query NewsPageQuery {
    site {
      siteMetadata {
        title
        description
        menuLinks {
          name
          link
          cl              
        }
        keywords
      }
    }
    sitePage {
      pluginCreator {
        name
      }
    }
    allContentfulNewsArticle {
      edges {
        node {
          articleId
          author {
            name
          }
          bodyRichText {
            raw
            references {
              id
            }
          }
          body {
            body
          }
          description {
            description
          }
          heroImage {
            file {
              url
            }
          }
          keywords
          publishDate(formatString: "MMMM Do YYYY")
          slug
          title
        }
      }
    }
  }
`